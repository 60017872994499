import Cards from '../pages/Cards.svelte';
import WebView from '../pages/WebView.svelte';
import PageBuilder from '../pages/PageBuilder.svelte';
import { JanusInternationalizationManagerToken } from './i18n';
import { getToken } from 'inversify-token';
import ROOT from '../inversify.config';
import { derived, writable, Writable } from 'svelte/store';
//import { JanusSignonManagerToken } from './sso';
import BlankPage from 'pages/BlankPage.svelte';
import { CardsPageContentManagerToken } from 'components/cards/common/CardsPageContentManager';
import { redirects } from './redirects';
import { includesChildFilteredSubstring, includesChildFilteredVariable } from './child-filter';
import Header from 'pages/Header.svelte';
import Footer from 'pages/Footer.svelte';
import { loadCardsEditorDependencies } from 'components/cards/common/CardsEditorDependencies';


//const signonManager = getToken(ROOT, JanusSignonManagerToken)
//const signonStatus = signonManager.getStatusComponent().store

const i18nManager = getToken(ROOT, JanusInternationalizationManagerToken);
export const currentLanguage = i18nManager.getCurrentLanguage();

const contentManager = getToken(ROOT, CardsPageContentManagerToken);

/*
let languageUnsubscribe: Unsubscriber = null;
const enableLanguageUrlSwitch = (to, from, resolve, reject) => {
  const initialLang = to.params.lang;
  //console.log('SET CURRENT LANGUAGE', initialLang);
  i18nManager.setCurrentLanguage(initialLang);
  // Listen for changes in language and change route as needed
  // we need to subscribe on the next tick because the setCurrentLanguage value may not have passed through to the store yet
  setTimeout(() => languageUnsubscribe = i18nManager.getCurrentLanguageStore().subscribe((lang) => {
    console.log('LANGUAGE CHANGE', lang, to);
    if (lang !== initialLang) {
      f7.view.main.router.navigate({
        name: to.name,
        params: { ...to.params, lang },
        query: (Object.keys(to.query).length)? to.query : undefined
      }, {
        //clearPreviousHistory: true
      });
    }
  }), 0);
  resolve();
};
const disableLanguageUrlSwitch = (to, from, resolve, reject) => {
  if (languageUnsubscribe) {
    languageUnsubscribe();
    languageUnsubscribe = null;
  }
  resolve();
};
*/

function setLanguageBasedOnUrl(this: any, to, from, resolve, reject) {
  //console.log('set language based on url', { ctx: this, to, from });
  if (to?.params?.lang) {
    if (from?.params?.lang && from?.params?.lang !== to?.params?.lang) {
      // hide the current page immediately to avoid user seeing janky transition
      for (const childEl of this.view.el.children) {
        childEl.style.visibility = 'hidden';
      }
    }
    i18nManager.setCurrentLanguage(to.params.lang);
  }
  //resolve();
  setTimeout(() => resolve(), 0);
}

const setRedirectUrlFromModal = (to, from, resolve, reject) => {
  const currentUrl = from?.url;
  if (currentUrl) {
    sessionStorage.setItem('previousUrl', currentUrl);
    sessionStorage.setItem('navigatedToListenLive', to.path.includes("listen-live"));
  }
  setTimeout(() => resolve(), 0);
}
export interface Route {
  query: { [key: string]: string };
  params: { [key: string]: string };
  url: string;
  path: string;
  // this is the route's source configuration (from the routes array below)
  route: {
    name: string;
    path: string;
  }
  name: string;
}

// This is set by YCApp.svelte
export const currentRoute: Writable<Route> = writable(null);

export const allViewsParams: Writable<Record<string, string>> = writable({});

export const shouldHideBcGame = writable<boolean>(false);

export const childFilteredPage = derived([currentRoute, allViewsParams, shouldHideBcGame], ([$route, $allViewParams, shouldHideBcGameValue]) => {
  try {
    if (!$route?.path) {
      return;
    }
    const pathSlug = $route.path.split('/').slice(-1)[0];
    return includesChildFilteredSubstring(pathSlug) || shouldHideBcGameValue || includesChildFilteredVariable($allViewParams);
  } catch (e) {
    console.error('exception while determining child filtered page', e);
  }
  return false;
}, false);

export const initialPageContentLoaded = writable<boolean>(false);

/*
const authGuard = (to, from, resolve, reject) => {
  const toListName = to.params.listName;
  const initialLang = to.params.lang;
  if( toListName === 'Profile') {
    return signonStatus.subscribe(signOn => {
      if (!signOn.loggedIn) {
        reject();
        f7.popup.open('.login-popup');
      } else {
        resolve()
      }
    })
  } else {
    resolve();
  }
}
*/

const modalOptions = {
  pushState: true,
  props: {
    isModal: true
  }
};

const popupOptions = {
  backdrop: true,
  backdropEl: '.popup-backdrop.cards-modal-popup-backdrop',
  closeByBackdropClick: true,
  closeOnEscape: true,
};

const modalEvents = {
  close: function(popup) {
    setTimeout(() => {
      const el = popup.el;
      popup.destroy();
      el.remove();
    }, 0);
  }
};

const cardsPathPrefixes = [ '/pages', '/modals' ];
export function isCardsPathUrl(url: string) {
  if (!url) {
    return false;
  }
  url = url.toLowerCase();
  return cardsPathPrefixes.findIndex((prefix) => url.startsWith(prefix)) >= 0;
}

function buildPagesPathFromSlug(slug: string) {
  return `/pages/${currentLanguage}/${slug}`;
}

const nowMillis = Date.now();

/*
const homeRedirect = (CONFIG.league === 'fa' && CONFIG.tricode === 'lei' && 
    nowMillis >= 1736005773000 && 
    nowMillis  < 1736013600000)?
  buildPagesPathFromSlug('match-centre?gameId=2444661') :
  buildPagesPathFromSlug('home');
*/
  
const homeRedirect = 
  (CONFIG.league === 'fa' && CONFIG.tricode === 'lei' && nowMillis >= 1736599500000 && nowMillis < 1736613900000)? buildPagesPathFromSlug('match-centre?gameId=2491526') : (
    (CONFIG.league === 'fa' && CONFIG.tricode === 'lei' && nowMillis >= 1736964900000 && nowMillis < 1736979300000)? buildPagesPathFromSlug('match-centre?gameId=2444674') : (
      (CONFIG.league === 'fa' && CONFIG.tricode === 'lei' && nowMillis >= 1737207900000 && nowMillis < 1737222300000)? buildPagesPathFromSlug('match-centre?gameId=2444685') :
        buildPagesPathFromSlug('home')
    )
  );

const fourOhFourRedirect = buildPagesPathFromSlug('404');

// WARNING: Redirects break prerendering for the website. Don't use redirects here unless absolutely necessary.
const routes = [
  {
    path: '/',
    //component: Home
    redirect: homeRedirect
  },
  {
    path: '/index.html',
    //component: Home,
    redirect: homeRedirect
  },
  {
    name: 'header',
    path: '/header',
    component: Header,
  },
  {
    name: 'footer',
    path: '/footer',
    component: Footer,
  },
  {
    name: 'page-with-slug',
    path: '/pages/:lang/:listName/:path1',
    component: Cards,
    beforeEnter: [ setLanguageBasedOnUrl ],
    /*
    beforeEnter: [authGuard, enableLanguageUrlSwitch],
    beforeLeave: disableLanguageUrlSwitch
    */
  },
  {
    name: 'pages',
    path: '/pages/:lang/:listName',
    component: Cards,
    beforeEnter: [ setLanguageBasedOnUrl ],
    /*
    beforeEnter: [authGuard, enableLanguageUrlSwitch],
    beforeLeave: disableLanguageUrlSwitch,
    */
  },
  {
    name: 'modal-with-slug',
    path: '/modals/:lang/:listName/:path1',
    options: modalOptions,
    popup: {
      ...popupOptions,
      component: Cards,
      on: modalEvents,
    },
    beforeEnter: [setLanguageBasedOnUrl, setRedirectUrlFromModal],
    /*
    beforeEnter: [authGuard, enableLanguageUrlSwitch],
    beforeLeave: [disableLanguageUrlSwitch]
    */
  },
  {
    name: 'modals',
    path: '/modals/:lang/:listName',
    options: modalOptions,
    popup: {
      ...popupOptions,
      component: Cards,
      on: modalEvents,
    },
    beforeEnter: [setLanguageBasedOnUrl, setRedirectUrlFromModal],
    /*
    beforeEnter: [authGuard, enableLanguageUrlSwitch],
    beforeLeave: disableLanguageUrlSwitch
    */
  },
  {
    path: '/webview',
    component: WebView,
  },
  {
    path: '/builder',
    component: PageBuilder,
    beforeEnter: function(to, from, resolve, reject) {
      loadCardsEditorDependencies().then(resolve).catch(reject);
    },
    beforeLeave: function ({resolve, reject}) {
      // "Relax," said the night man...
      if (typeof reject === 'function') {
        reject();
      }
    }
  },
  {
    path: '/login-redirect',
    component: BlankPage,
  },
  {
    path: '(.*)',
    redirect: function (route, resolve, reject) {
      console.log('catch all redirect', route);
      const userPath: string = route.path.toLowerCase();
      let redirectPath: string = redirects[userPath];
      if (!redirectPath && userPath?.endsWith('/')) {
        redirectPath = redirects[userPath.slice(0, -1)];
      }
      let matches: RegExpMatchArray;
      const isInCrowd = (CONFIG.league === 'fa' && CONFIG.tricode === 'bir');
      const isPulseLive = (CONFIG.league === 'fa' && CONFIG.tricode === 'bha') || (CONFIG.league === 'fa' && CONFIG.tricode === 'lei');
      // test exact matches
      if (redirectPath) {
        if (redirectPath.startsWith('http://') || redirectPath.startsWith('https://')) {
          window.location.href = redirectPath;
          reject();
          return;
        } else if (redirectPath.startsWith('/')) {
          resolve(redirectPath);
          return;
        } else {
          resolve(buildPagesPathFromSlug(redirectPath));
          return;
        }
      // test InCrowd news article matches
      } else if (isInCrowd && (matches = userPath.match(/^\/news(?:\/[^\/]+)?\/([^\/]+)$/))) {
        const href = contentManager.getMediaTypeConfiguration('article', matches[1]).linkUrl.href;
        resolve(href);
        return;
      } else if (isInCrowd && (matches = userPath.match(/^\/bluestv(?:\/[^\/]+)?\/([^\/]+)$/))) {
        const href = contentManager.getMediaTypeConfiguration('video', matches[1]).linkUrl.href;
        resolve(href);
        return;
      // test Pulse news article matches
      } else if (isPulseLive && (matches = userPath.match(/^\/news\/\d+\/([^\/]+)/))) {
        const href = contentManager.getMediaTypeConfiguration('article', matches[1]).linkUrl.href;
        resolve(href);
        return;
      } else if (isPulseLive && (matches = userPath.match(/^\/tv\/\d+\/([^\/]+)/))) {
        resolve(contentManager.getMediaTypeConfiguration('video', matches[1]).linkUrl.href);
        return;
      } else if (isPulseLive && (matches = userPath.match(/^\/galleries\/\d+\/([^\/]+)/))) {
        resolve(contentManager.getMediaTypeConfiguration('gallery', matches[1]).linkUrl.href);
        return;
      }
      // otherwise go to 404
      resolve(fourOhFourRedirect);
    }
  },
];

export default routes;
